export const apiDomain = process.env.VUE_APP_BACKEND
export const LoginURL = apiDomain + 'oauth/token'
export const homeLink = process.env.VUE_APP_FRONTEND
export const fileServer = apiDomain

export const clientId = '93653827-41fa-4dc4-bc42-7349280e37a7'
export const clientSecret = 'cyLPlmkgjRkjiVjz1z1OITA47id883CHl6QYqZX2'

export const getHeader = function () {
    const tokenData = JSON.parse(window.localStorage.getItem('authUser'))
    const headers = {
        'Accept' : 'application/json',
        'Authorization' : 'Bearer ' + tokenData.access_token
    }

    return headers
}

export const getcurrentDate = function ()
{
  const current       = new Date()
  const currentMonth  = String((current.getMonth()+1)).padStart(2, '0')
  const currentDay    = String(current.getDate()).padStart(2, '0')
  const currentDate   = current.getFullYear()+'-'+currentMonth+'-'+currentDay

  return currentDate
}

export const getUserInfo = function () {
    const loginUserData = JSON.parse(window.localStorage.getItem('loginUser'))
    return loginUserData
}
